import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import {Navbar, Nav, FormControl, Dropdown, Badge, ListGroup, Media, Form} from 'react-bootstrap'
import layoutHelpers from './helpers'
import axios from "axios";
import helpers from "./helpers";
import ConnectTo from "../../components/Tools/ConnectTo";
import AuthService from "../../services/auth-service";
import DepartmentSwitcher from "../../components/Tools/DepartmentSwitcher";


class LayoutNavbar extends Component {
  constructor(props) {
    super(props);

    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl';
    this.fullName = helpers.getUser().fullName;

    this.state = {search: this.props.match.params.query ? decodeURIComponent(this.props.match.params.query) : "", isOpen: false};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed()
  }

  logout(e) {

    axios.get(helpers.getAPI()  + '/partner/api/logout')
    .then(res => {
      AuthService.logout();
    }).catch(res => {
      AuthService.logout();
    })
  }

  myProfile() {
    this.props.history.push("/users/view/"+helpers.getUser().id)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = "search"
    this.setState({
      [name]: value
    });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    this.props.history.push('/search/'+encodeURIComponent(this.state.search))
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Navbar bg={this.props.navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">
        {/* Brand demo (see src/demo.css) */}
        <Navbar.Brand as={NavLink} to="/" className="app-brand demo d-lg-none py-0 mr-4">
          <span className="app-brand-logo demo bg-primary">
            <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><defs><linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse"><stop stopOpacity=".25" offset="0"></stop><stop stopOpacity=".1" offset=".3"></stop><stop stopOpacity="0" offset=".9"></stop></linearGradient><linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlinkHref="#a"></linearGradient><linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlinkHref="#a"></linearGradient></defs><path style={{fill: '#fff'}} transform="translate(-.1)" d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"></path><path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z" fill="url(#a)"></path><path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z" fill="url(#e)"></path><path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z" fill="url(#d)"></path></svg>
          </span>

        </Navbar.Brand>

        {/* Sidenav toggle (see src/demo.css) */}
        {this.props.sidenavToggle && (
            <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
              <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu text-large align-middle"></i>
              </Nav.Item>
            </Nav>
        )}

        <Navbar.Toggle />

        <Navbar.Collapse>
          {/* Divider */}
          <hr className="d-lg-none w-100 my-2" />

          <Nav className="align-items-lg-center bg-light px-4" style={{width: '100%', borderRadius:'16px'}}>
            {/* Search */}
            <Form onSubmit={this.onSubmitHandler} style={{width: '100%'}}>
              <label className="nav-item navbar-text navbar-search-box p-0 active" style={{width: '100%'}}>
                <i className="ion ion-ios-search navbar-icon align-middle"></i>
                <span className="navbar-search-input pl-2" style={{width: '100%'}}>
                <Form.Control className="navbar-text mx-2" name={"search2"} value={this.state.search} onChange={this.handleInputChange} placeholder="Search..." style={{width: '100%'}} autoComplete={"off"} />
              </span>
              </label>
            </Form>
          </Nav>

          <Nav>
            <div className="nav-item dropdown">
              <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleDropdown();
                  }}
              >

              <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <img
                    src={`${process.env.PUBLIC_URL}/img/avatars/1.png`}
                    className="d-block ui-w-30 rounded-circle"
                    alt="User"
                />
                <span className="px-1 mr-lg-2 ml-2 ml-lg-0">{this.fullName}</span>
              </span>
              </a>
              <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{marginLeft: '-20px'}}>
                <a href="#" className="dropdown-item" onClick={() => this.myProfile()}>
                  <i className="ion ion-ios-person text-lightest"></i> &nbsp; My Profile
                </a>
                <ConnectTo/>
                <DepartmentSwitcher/>
                <a href="#" className="dropdown-item" onClick={() => this.logout()}>
                  <i className="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out
                </a>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

export default connect(store => ({
  navbarBg: store.theme.navbarBg
}))(LayoutNavbar)
